export default {

    validate({dispatch, commit}) {

        commit('markAsInvalid')
        commit('clearValidationErrors')
        dispatch('validateEpisode')
        dispatch('validateDirectives')
        dispatch('validateAssessments')
        dispatch('validatePatient')
        dispatch('validateFamily')
        dispatch('validateVitalSigns')
        dispatch('validateCareTeam')
        dispatch('validateImmunizations')
        dispatch('validateInterventions')
        dispatch('validateMedications')
        dispatch('validateStatus')
    },

    validateStatus({state, commit}) {

        const status = state.episode.validation.episodeIsValid
        && state.episode.validation.directivesAreValid
        && state.episode.validation.assessmentsAreValid
        && state.episode.validation.patientIsValid
        && state.episode.validation.familyIsValid
        && state.episode.validation.vitalSignsAreValid
        && state.episode.validation.careTeamIsValid
        && state.episode.validation.immunizationsAreValid
        && state.episode.validation.interventionsAreValid
        && state.episode.validation.medicationsAreValid

        if(status === true) {

            commit('markAsValid')
        } else {

            commit('markAsInvalid')
        }
    },
}